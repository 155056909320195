<template>
  <div class="coupon-list-box">
    <div class="coupon-item" v-for="item in list" :key="item.id">
      <div class="item-left">
        <img
          :src="
            item.productType == 30
              ? require('../assets/images/bill.png')
              : item.promotionImg || defaultProductImg
          "
          alt=""
        />
        <span>- 指定规格属性可用 -</span>
      </div>
      <div class="item-right">
        <img
          v-if="item.reviceStatus == 20"
          class="get-icon"
          src="../assets/images/water_mark.png"
          alt=""
        />
        <img
          v-if="item.reviceStatus == 40"
          class="get-icon"
          src="../assets/images/used1.png"
          alt=""
        />
        <div class="circle circle-1"></div>
        <div class="circle circle-2"></div>
        <div class="right-top">
          <div class="coupon-name">{{ item.couponName }}</div>
          <div class="coupon-price">
            <span
              >领券下单优惠{{ mathManage.formatMoney(item.amount, 2) }}元</span
            >
          </div>
        </div>
        <div class="right-bottom">
          <div class="after-price">
            <span class="title">券后价</span> <span class="unit">￥</span
            ><span class="price">{{
              mathManage.formatMoney(item.couponAfterPrice, 2)
            }}</span>
          </div>
          <div class="product-price">
            <span class="price"
              >商品价
              <i
                >￥{{ mathManage.formatMoney(item.productSalePrice, 2) }}</i
              ></span
            >
          </div>
        </div>
        <span
          @click="takeCoupon(item)"
          :class="renderClass(item.reviceStatus)"
          >{{ couponStatus[item.reviceStatus] }}</span
        >
      </div>
    </div>
    <card-mobile-view
      v-model="showViewMobile"
      :couponData="couponData"
      :productType="productType"
      @onCancel="onCancel"
    />
    <login-box
      v-model="showLogin"
      @handleCancel="handleCancelLogin"
      @onOk="loginOk"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import mathManage from "@/utils/mathManage";
import { couponStatus } from "@/utils/dictionary";
import { ReceiveCoupon } from "@/api/common";
import CardMobileView from "./CardMobileView.vue";
import LoginBox from "@/components/LoginBox.vue";
import debounce from "lodash/debounce";
export default {
  components: { CardMobileView, LoginBox },
  name: "CouponList",
  props: {
    list: {
      default: () => [],
    },
  },
  data() {
    return {
      mathManage,
      couponStatus,
      showViewMobile: false,
      couponData: {},
      productType: "",
      showLogin: false,
    };
  },
  computed: {
    ...mapState(["defaultProductImg", "isLogin", "isInWx"]),
  },

  methods: {
    ...mapActions(["dispatchGetCardCount"]),

    handleCancelLogin() {
      this.showLogin = false;
    },

    loginOk() {
      this.showLogin = false;
      this.$emit("getList");
    },

    // 领取/使用优惠券
    takeCoupon(data) {
      this.$nextTick(() => {
        const { reviceStatus, upperProductId = "", productType } = data;
        if (!this.isLogin) {
          if (this.isInWx) {
            this.$wxLogin();
            return;
          }
          this.showLogin = true;
          return;
        }
        // 10：立即领取  20：去使用  30：继续领取  40：已领取
        if (reviceStatus == 40) return;
        if (reviceStatus == 20) {
          // 话费
          if (productType == 30 || productType == 40) {
            this.$router.push(
              `/mall/phoneBill?productType=${productType}&productId=${upperProductId}`
            );
          } else {
            this.$router.push(
              `/mall/ProductDetails?productId=${upperProductId}`
            );
          }
        }
        if (reviceStatus == 10 || reviceStatus == 30) {
          this.reviceCoupon(data);
        }
      });
    },

    // 领券
    reviceCoupon: debounce(async function (data = {}) {
      if (!data.batchId) {
        this.$toast("卡券异常！");
        return;
      }
      this.$loading("请稍等...");
      try {
        const res = await ReceiveCoupon({
          batchId: data.batchId,
        });
        this.$toast.clear();
        if (res && res.code == 0) {
          this.dispatchGetCardCount();
          this.couponData = { productId: data.upperProductId };
          this.productType = data.productType;
          this.$emit("getList", data.batchId);
          this.$toast("领取成功！");
        } else {
          this.$toast(res.message);
        }
      } catch (error) {
        this.$toast.clear();
        const { status } = error.response || {};
        if (status == 401) {
          if (this.isInWx) {
            this.$wxLogin();
            return;
          }
          this.showLogin = true;
        }
      }
    }, 500),

    onCancel() {
      this.showViewMobile = false;
      this.productType = "";
      this.couponData = {};
      this.$emit("getList");
    },

    renderClass(status) {
      if (status == 10 || status == 30) {
        return "action-btn";
      }
      if (status == 40) {
        return "action-btn isUse";
      }
      return "action-btn used";
    },
  },
};
</script>

<style lang="less" scoped>
.coupon-list-box {
  background: #f5f5f5;
  padding: 0.24rem 0.24rem 1rem 0.24rem;
  box-sizing: border-box;

  .coupon-item {
    padding: 0.24rem 0.24rem 0.2rem 0.24rem;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 0.16rem;
    display: flex;
    margin-bottom: 0.16rem;
    min-height: 2.28rem;

    .item-left {
      width: 2.28rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      margin-right: 0.26rem;

      img {
        width: 100%;
        height: 1.36rem;
        border-radius: 0.08rem;
      }

      span {
        font-size: 0.2rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #d1d1d1;
        margin-top: 0.16rem;
      }
    }

    .item-right {
      flex: 1;
      border-left: 0.02rem dashed #eaeaea;
      padding-left: 0.2rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      .get-icon {
        position: absolute;
        right: 0;
        top: 0;
        width: 1.12rem;
        height: 1.12rem;
        z-index: 1;
      }

      .circle {
        width: 0.26rem;
        height: 0.26rem;
        border-radius: 50%;
        background-color: #f5f5f5;
        position: absolute;
        left: 0;
        margin-left: -0.13rem;
        z-index: 5;

        &.circle-1 {
          top: -0.38rem;
        }
        &.circle-2 {
          bottom: -0.34rem;
        }
      }

      .right-bottom {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      .coupon-name {
        font-size: 0.25rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        position: relative;
        z-index: 2;
        display: -webkit-box;
        /* 弹性盒旧式语法 */
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        /* 限制两行 */
        overflow: hidden;
      }

      .coupon-price {
        margin-top: 0.04rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        // padding: 0.04rem 0;

        span {
          border-radius: 0.06rem;
          border: 0.01rem solid #fd3b3f;
          font-size: 0.18rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #fd3b3f;
          padding: 0 0.08rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .after-price {
        font-size: 0.2rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fd3b3f;
        display: flex;
        align-items: center;
        // vertical-align: middle;

        .title {
          padding-top: 0.08rem;
        }

        .unit {
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          padding-top: 0.06rem;
        }

        .price {
          font-size: 0.32rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #fd3b3f;
        }
      }

      .product-price {
        height: 0.26rem;
        display: flex;

        .price {
          font-size: 0.2rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #d1d1d1;

          i {
            font-style: normal;
            text-decoration: line-through;
          }
        }
      }

      .action-btn {
        position: absolute;
        bottom: 0;
        right: 0;
        min-width: 1.44rem;
        height: 0.44rem;
        background: linear-gradient(90deg, #fe5a34 0%, #fd3940 100%);
        border-radius: 0.22rem;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        &.used {
          border: 0.02rem solid #ff5b42;
          background: #fff;
          color: #ff5b42;
        }

        &.isUse {
          color: #d3d3d3;
          border: 0.02rem solid #d3d3d3;
          background: #fff;
        }
      }
    }
  }
}
</style>